var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "proxy", staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailInfo",
            attrs: { title: "서명하세요.", topClass: "topcolor-orange" },
          },
          [
            _c(
              "template",
              { slot: "card-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.popupParam.documentStatusCd !== "ESC000010"
                      ? _c("c-btn", {
                          attrs: {
                            label: "서명등록",
                            color: "blue",
                            showLoading: false,
                          },
                          on: { btnClicked: _vm.saveAsSvg },
                        })
                      : _vm._e(),
                    _vm.popupParam.documentStatusCd !== "ESC000010"
                      ? _c("c-btn", {
                          attrs: {
                            label: "지우기",
                            color: "red",
                            showLoading: false,
                          },
                          on: { btnClicked: _vm.eraseSignature },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("template", { slot: "card-detail" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12" },
                  [
                    _c("VueSignaturePad", {
                      ref: "signaturePad",
                      attrs: { width: _vm.windowWidth, height: "200px" },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }