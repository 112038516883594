<template>
  <div class="row" ref="proxy">
    <div class="col-md-12 col-lg-12">
      <c-card title="서명하세요." class="cardClassDetailInfo" topClass="topcolor-orange">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="popupParam.documentStatusCd !== 'ESC000010'" label="서명등록" color="blue" :showLoading="false" @btnClicked="saveAsSvg" />
            <c-btn v-if="popupParam.documentStatusCd !== 'ESC000010'" label="지우기" color="red" :showLoading="false" @btnClicked="eraseSignature" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="row">
            <div class="col-xs-12 col-sm-12">
              <VueSignaturePad :width="windowWidth" height="200px" ref="signaturePad" />
            </div>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>
<script>
import VueSignaturePad from 'vue-signature-pad';
import Vue from 'vue';
import transactionConfig from '@/js/transactionConfig';
let signaturePad = null;
Vue.use(VueSignaturePad)
export default {
  name: 'signature-pop',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          electronSignature: '',
          attendeesId: '',
          eduEducationId: '',
          regUserId: '',
          chgUserId: '',
          documentStatusCd: ''
        }
      },
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      updateSignatureUrl: '',
      windowWidth: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.updateSignatureUrl = transactionConfig.sop.edu.plan.update.targetUser.signature.url;
      if (!this.$refs.signaturePad) {
        return;
      }

      this.windowWidth = (document.documentElement.clientWidth - 45) + 'px';
      signaturePad = this.$refs.signaturePad
      
      if (this.popupParam.documentStatusCd === 'ESC000010') {
        signaturePad.lockSignaturePad();
      }

      if (this.popupParam.electronSignature) {
        signaturePad.fromDataURL(this.popupParam.electronSignature);
      }
    },
    saveAsSvg() {
      var data = ''
      if (signaturePad.saveSignature().isEmpty) {
        data = ''
      } else {
        data = signaturePad.saveSignature().data;
      }
      this.popupParam.electronSignature = data;
      
      if (!this.popupParam.electronSignature) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '서명하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '서명등록하시겠습니까?(등록할 시 출석 및 이수완료가 됩니다.)',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.popupParam.regUserId = this.$store.getters.user.userId
          this.popupParam.chgUserId = this.$store.getters.user.userId
          this.$http.url = this.updateSignatureUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.popupParam;
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        
        },
      });
      }
    },
    eraseSignature() {
      signaturePad.clearSignature();
      this.popupParam.electronSignature = '';
    },
    reset() {
      Object.assign(this.$data.info, this.$options.data().info);
    },
  }
};
</script>